import { Storage } from '@capacitor/storage';
import { useEffect, useState } from "react";
import { groupBy, isArray, isPlainObject, mapValues, toPairs } from "lodash";
import moment from "moment";
import "moment/locale/es";

moment().locale("es");

// OBJECTS
export const nest = function (seq, keys) {
  if (!keys.length) return seq;
  var first = keys[0];
  var rest = keys.slice(1);
  return mapValues(groupBy(seq, first), function (value) {
    return nest(value, rest);
  });
};

export const PLATFORM = {
  IOS: "iOS",
  ANDROID: "Android",
  WINDOWSPHONE: "Windows Phone",
};

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return PLATFORM.WINDOWSPHONE;
  }

  if (/android/i.test(userAgent)) {
    return PLATFORM.ANDROID;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return PLATFORM.IOS;
  }

  return "unknown";
};

// MATH
export const percentOf = (num1, num2) =>
  Math.round((num1 / num2) * 100 * 10) / 10;

export const taskPercent = (start, end) => {
  var now = moment();
  start = moment(start, "YYYY-MM-DD") || moment(now).startOf("day");
  end = moment(end) || moment(now).endOf("day");
  var totalMillisInRange = end.valueOf() - start.valueOf();
  var elapsedMillis = now.valueOf() - start.valueOf();
  return Math.round(Math.min(100, 100 * (elapsedMillis / totalMillisInRange)));
};

// DATES
export const dateFormatter = (date) => {
  return moment(date).format("L");
};

export const randomDate = (start, end) => {
  var d = new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  ),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [day, month, year].join("/");
};

export const dateObjFormatter = (date, separator = "/") => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [day, month, year].join(separator);
};

export const momentToDate = (date) => {
  if (!date) {
    return new Date();
  }
  return new Date(date);
};

// FILES
export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const bytesToSize = (bytes) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export const blobToFile = (theBlob, fileName) => {
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
};

// DEVICE
export const useIsMobile = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => setWidth(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  return width <= 1200;
};

// FORM
const handleInitDataTypes = (data) => {
  if (!data) return;

  if (isPlainObject(data) && data.id) {
    return data.id;
  }
  if (isPlainObject(data) && !data.id) {
    return data;
  }
  if (isArray(data)) {
    return data;
  }
  return data;
};

export const formatFormInitialData = (data) => {
  const fields = toPairs(data).reduce((flds, a) => {
    flds.push({ name: a[0], value: handleInitDataTypes(a[1]) });
    return flds;
  }, []);
  return fields;
};

// COOKIES
export const setCookie = async (key, value, exdays) => {
  await Storage.set({ key, value: JSON.stringify(value) });
};

export const getCookie = async (key) => {
  const c = await Storage.get({ key: key });
  return JSON.parse(c.value);
};

export const deleteCookie = async (key) => {
  await Storage.remove({ key });
};

export const getCurrentChanges = (
  change,
  changes,
  data,
  setdisabled,
  profile
) => {
  const entrie = Object.entries(change)[0][0];
  const previous = changes.filter((x) => Object.entries(x)[0][0] !== entrie);
  const postChanges = previous;
  if (data && data[entrie] !== Object.entries(change)[0][1]) {
    setdisabled(true);
    const placeholder = document.getElementById(
      Object.entries(change)[0][0]
    ).placeholder;
    postChanges.push({
      ...change,
      placeholder,
      date: moment(),
      user: profile._id,
    });
  }
  return postChanges;
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });


export const conditions = [
  {
    title: "Objeto del contrato y presupuesto",
    description: "El cliente firmará con TECNOLOGIES INDUSTRIALS PALLICER SL el contrato de prestación de servicios, el cual incluirá el presupuesto correspondiente. Cualquier solicitud de servicios adicionales deberá ser expresamente indicada por el cliente y estará sujeta a un presupuesto adicional."
  },
  {
    title: "Independencia en la ejecución",
    description: "TECNOLOGIES INDUSTRIALS PALLICER SL mantendrá plena autonomía e independencia en la ejecución del trabajo. Aunque se respetarán los plazos estimados, no se estará sujeto a un horario específico impuesto por el cliente, salvo acuerdo expreso entre ambas partes."
  },
  {
    title: "Corrección de errores y modificaciones",
    description: "El cliente podrá notificar cualquier error o deficiencia para su corrección. Sin embargo, las modificaciones de diseño o funcionalidad no incluidas en el alcance original del proyecto implicarán la elaboración de un nuevo presupuesto."
  },
  {
    title: "Facturación y pagos",
    description: "Cada fase del proyecto dará lugar a la emisión de una factura, cuyo pago será requisito indispensable para la continuidad de los trabajos por parte de TECNOLOGIES INDUSTRIALS PALLICER SL. Los retrasos en los pagos o la falta de abono podrán resultar en la suspensión temporal o terminación del contrato."
  },
  {
    title: "Garantía",
    description: "Ciertos servicios, como el desarrollo de sitios web (especificar según sea necesario), contarán con una garantía de 6 meses en relación con fallos de funcionamiento. No obstante, TECNOLOGIES INDUSTRIALS PALLICER SL no será responsable de errores derivados del mal uso por parte del cliente."
  },
  {
    title: "Propiedad intelectual",
    description: "TECNOLOGIES INDUSTRIALS PALLICER SL garantiza que sus servicios no infringirán derechos de propiedad intelectual de terceros. Salvo que el cliente indique lo contrario por escrito, la empresa se reserva el derecho de firmar los trabajos realizados (por ejemplo, en el pie de página de un sitio web) y utilizarlos con fines demostrativos en presentaciones comerciales."
  },
  {
    title: "Confidencialidad",
    description: "Ambas partes acuerdan que toda información confidencial intercambiada durante la ejecución del presente contrato, ya sea de naturaleza técnica, comercial, financiera, estratégica o de cualquier otra índole, se mantendrá en estricta confidencialidad, y las obligaciones de confidencialidad establecidas en este contrato continuarán vigentes incluso después de la finalización del mismo, por cualquier causa. En particular, ambas partes se comprometen a no divulgar, utilizar ni permitir el uso de dicha información confidencial para ningún propósito ajeno a la relación contractual, por un periodo indefinido y en todo caso por el tiempo necesario para proteger adecuadamente los intereses legítimos de las partes involucradas. Las partes reconocerán que el incumplimiento de esta obligación constituirá una violación grave de sus compromisos contractuales, dando derecho a la parte afectada a tomar las acciones legales pertinentes."
  },
  {
    title: "Obligaciones del cliente",
    description: "El cliente deberá proporcionar toda la información y documentación necesaria para la correcta ejecución del proyecto. La falta de entrega de esta información podrá ocasionar retrasos en los plazos acordados."
  },
  {
    title: "Finalización del contrato",
    description: "El contrato finalizará con la culminación de los servicios contratados o por el incumplimiento de cualquiera de las partes. Asimismo, podrá resolverse por mutuo acuerdo de las partes."
  },
  {
    title: "Jurisdicción y legislación aplicable",
    description: "En caso de discrepancias o disputas derivadas de este contrato, las partes se comprometen a intentar resolverlas de manera amistosa mediante negociación directa. Si no se logra un acuerdo en un plazo de 30 días, se procederá a someter la cuestión a la jurisdicción de los tribunales de Palma de Mallorca, aplicándose en todo caso la legislación española vigente."
  }
];